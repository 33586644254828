<template>
  <div data-test="us-hero-banner" class="us-hero-banner">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'USHeroBanner',
  props: {
    bannerHeight: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

.us-hero-banner {
  --banner-height--sm: 417px;
  --banner-height--lg: 417px;
  --banner-height--sm: v-bind("bannerHeight ? bannerHeight : '417px'");
  --banner-height--lg: v-bind("bannerHeight ? bannerHeight : '417px'");
  --radius: 100vw;

  background: var(--color--neutral-a4);
  clip-path: circle(var(--radius) at 50% calc(100% - var(--radius)));
  height: var(--banner-height--sm);
  min-width: breakpoint.$breakpoint--min;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: #{breakpoint.$breakpoint--min - 1px}) {
    --radius: #{breakpoint.$breakpoint--min};
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    height: var(--banner-height--lg);
  }
}
</style>
